import { createClient } from 'microcms-js-sdk';

const config = Object.freeze({
  get:{endpoint: 'news'},
  createClient:{
    serviceDomain: 'thebattlenews', // サービスドメイン
    apiKey: 'TxX0ZkwUaUcYfwKP5Y4gxnxBG98om5VzJlme', // APIキー
  }
})
const client = createClient(config.createClient);

export const getNews = (id)=>client
      .get({
        ...config.get,
        contentId: id,
      })

export const getNewsList = (limit)=>client
      .get({
        ...config.get,
        queries: { orders: '-releaseDate', ...(limit?{limit: limit}:{}) },
      })
