import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { SliderButton } from "@typeform/embed-react";
import { getNewsList } from '../helper/microcmsClient';

import NewsPage01 from "../compressed/NewsPage01.webp";
import battleLogo from "../compressed/battleLogo.webp";
import SPNews01 from "../compressed/SPNews01.webp";

const NewsPage = ({
  useWindowWidth,
  handlerToggle,
  contactDetailBlurInView,
}) => {
  const [newsList, setNewsList] = useState([]);
  const capitalize = (text) => text.toUpperCase();
  const windowWidth = useWindowWidth();
  const blur = useInView({
    threshold: 0, //0%見えたら検知
    triggerOnce: true,
  });
  useEffect(() => {
    getNewsList()
      .then((res) => setNewsList(res.contents))
      .catch((error) => {
        // エラー発生時の処理
        alert('ページが見つかりません');
        window.location.href = '/'; // トップページにリダイレクト
      });
  }, []);

  if (windowWidth > 496) {
    return (
      <>
        <section id="NewsPage" className="NewsPage flex" ref={blur.ref}>
          {contactDetailBlurInView ? (
            <></>
          ) : (
            <header className="flex column align-center justify-between PCHeaderDetailAnimation">
              <div className="headerImage flex align-center justify-center">
                <Link to="/">
                  <img loading="lazy" src={battleLogo} alt="" />
                </Link>
              </div>
              <ul className="flex align-center justify-center vertical_rl">
                <li>
                  <Link to="/">{capitalize("home")}</Link>
                </li>
                <li>
                  <Link to="/news" className="strike-out-line">
                    {capitalize("news")}
                  </Link>
                </li>
                <li>
                  <Link to="/projects">{capitalize("projects")}</Link>
                </li>
                <li>
                  <Link to="/company">{capitalize("company")}</Link>
                </li>
                {/* <li>
                  <Link to="/recruit">{capitalize("recruit")}</Link>
                </li> */}
              </ul>
            </header>
          )}
          <div className="Container page_contanier flex">
            <div className="upper flex column justify-between w-full h-full">
              {blur.inView && (
                <div className="BigNumber blurNumber w-full">
                  <h1 className="w-full user-select-none">01</h1>
                </div>
              )}
              <div className="downer flex">
                <div
                  className={`subTitle flex align-end ${blur.inView ? "blurTitle" : "invisible"
                    }`}
                >
                  <span className="user-select-none">{capitalize("news")}</span>
                </div>
                {blur.inView && (
                  <div className="text flex column align-end justify-end gap-20 blurText">
                    <div className="jaText">
                      <span>
                        わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                      </span>
                    </div>
                    <div className="enText">
                      <span>
                        We continue to explore the possibilities of "web3 x
                        entertainment" utilizing blockchain and NFT
                        technologies. We sincerely hope that the "things" we
                        create will contribute to the continuation and
                        development of Japan's creative culture, and by
                        extension, the sustainability of Otaku culture around
                        the world.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="NewsPageLeft flex column">
              <img loading="lazy" src={NewsPage01} alt="" className="w-full" />
            </div>
          </div>
        </section>
        <section id="NewsDetail" className="NewsDetail flex Cormorant_Garamond">
          {newsList.map((news) => {
            const releaseDate = new Date(news.releaseDate)
            const year = releaseDate.getFullYear()
            const monthDay = `${String(releaseDate.getMonth() + 1).padStart(2, '0')}.${String(releaseDate.getDate()).padStart(2, '0')}`
            const dayOfWeek = releaseDate.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
            return (
              <div className="NewsDetailContainer flex w-full h-full">
                <div className="dateLine h-full flex align-start justify-end">
                  <div className="vertical_rl">{year}</div>
                </div>
                <div className="content align-center justify-between flex column w-full">
                  <div className="subContent flex column align-start justify-center w-full">
                    <div className="contentUp flex align-center justify-center">
                      <div className="date">
                        <span>{`${monthDay}(${dayOfWeek})`}</span>
                      </div>
                    </div>
                    <div className="contentDown flex column align-start justify-between h-full">
                      <div className="newsImage flex align-start justify-center">
                        <Link to={`/news/${news.id}`}>
                          <img loading="lazy" src={news.mainImage.url} alt={news.title} />
                        </Link>
                      </div>
                      <div className="newsContent flex column">
                        <Link to={`/news/${news.id}`} className="flex column gap-12">
                          <div className="mainTitle">
                            <span>{news.title}</span>
                          </div>
                          <div className="article">
                            <span>{news.enTitle}</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="contentRight flex align-end justify-end w-full">
                    <span>
                      <Link to={`/news/${news.id}`}>View More</Link>
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </section>
      </>
    );
  } else {
    return (
      <section className="spNewsPage" ref={blur.ref}>
        <div className="spDetailContainer">
          <header className="fixed flex align-center justify-between w-full">
            <div className="logo flex align-center justify-center">
              <a href="/">
                <img loading="lazy" src={battleLogo} alt="" className="w-80" />
              </a>
            </div>
            <div className="menuBtn">
              <button onClick={handlerToggle}>{capitalize("menu")}</button>
            </div>
          </header>
          <div className="contactText fixed mix_difference">
            <SliderButton id="wJm1bGdB" className="SPtypeformBtn">
              {capitalize("contact")}
            </SliderButton>
          </div>
          <div className="spDetailDowner flex column relative">
            {blur.inView && (
              <div className="titlenumber absolute blurNumber">
                <span>01</span>
              </div>
            )}
            <div className="titleimage relative">
              <div
                className={`absolute white mix_difference ${blur.inView ? "blurTitle" : ""
                  }`}
              >
                <span>{capitalize("news")}</span>
              </div>
              <div className="">
                <img loading="lazy" src={SPNews01} alt="" className="w-full" />
              </div>
            </div>
            {blur.inView && (
              <div className="titletext flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    We continue to explore the possibilities of "web3 x
                    entertainment" utilizing blockchain and NFT technologies. We
                    sincerely hope that the "things" we create will contribute
                    to the continuation and development of Japan's creative
                    culture, and by extension, the sustainability of Otaku
                    culture around the world.
                  </span>
                </div>
              </div>
            )}
            {newsList.map((news) => {
              const releaseDate = new Date(news.releaseDate)
              const year = releaseDate.getFullYear()
              const monthDay = `${String(releaseDate.getMonth() + 1).padStart(2, '0')}.${String(releaseDate.getDate()).padStart(2, '0')}`
              const dayOfWeek = releaseDate.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
              return (
                <div className="content flex column align-start justify-center w-full h-full gap-50">
                  <div className="flex column align-start justify-center gap-30">
                    <div className="year w-full">
                      <span>{year}</span>
                    </div>
                    <div className="date-image flex align-start justify-between w-full">
                      <div className="date">
                        <span className="first">{monthDay}</span>
                        <span className="second">（{dayOfWeek}）</span>
                      </div>
                      <div className="image">
                        <img loading="lazy" src={news.mainImage.url} alt={news.title} className="w-full" />
                      </div>
                    </div>
                    <div className="text flex column gap-20">
                      <div className="ja">
                        <span>{news.title}</span>
                      </div>
                      <div className="en">
                        <span>{news.enTitle}</span>
                      </div>
                    </div>
                  </div>
                  <div className="allview flex align-center justify-end w-full">
                    <span>
                      <Link to={`/news/${news.id}`}>All View</Link>
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    );
  }
};

export default NewsPage;
