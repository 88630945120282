import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useInView } from "react-intersection-observer";
import { getNewsList } from '../helper/microcmsClient';


const TopNews = ({ scrollNewsRef, useWindowWidth }) => {
  const [newsList, setNewsList] = useState([]);
  const windowWidth = useWindowWidth();
  const capitalize = (text) => text.toUpperCase();
  const blur = useInView({
    threshold: 0.1, //10%見えたら検知
    triggerOnce: true,
  });
  useEffect(() => {
    getNewsList(3)
      .then((res) => setNewsList(res.contents))
      .catch((error) => {
        // エラー発生時の処理
        alert('ページが見つかりません');
        window.location.href = '/'; // トップページにリダイレクト
      });
  }, []);

  if (windowWidth > 496) {
    return (
      <section id="TopNews" className="h-full flex" ref={scrollNewsRef}>
        <div className="flex" ref={blur.ref}>
          <div className="TopNewsContainer flex align-center justify-between Cormorant_Garamond relative">
            {blur.inView && (
              <div className="BigNumber blurNumber absolute user-select-none">
                <h1>01</h1>
              </div>
            )}
            <div className="TopNewsLeft flex column justify-end h-full">
              <div className="TopNewsText">
                <div
                  className={`subTitle ${blur.inView ? "blurTitle" : "invisible"
                    }`}
                >
                  <span className="user-select-none">{capitalize("news")}</span>
                </div>
                {blur.inView && (
                  <div className="flex column gap-5 blurText">
                    <div className="jaText">
                      <span>
                        わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                      </span>
                    </div>
                    <div className="enText">
                      <span>
                        We continue to explore the possibilities of "web3 x
                        entertainment" utilizing blockchain and NFT
                        technologies. We sincerely hope that the "things" we
                        create will contribute to the continuation and
                        development of Japan's creative culture, and by
                        extension, the sustainability of Otaku culture around
                        the world.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="TopNewsRight flex column justify-start align-center h-full">
              <div>
                {newsList.map((news) => {
                  const releaseDate = new Date(news.releaseDate)
                  const year = releaseDate.getFullYear()
                  const monthDay = `${String(releaseDate.getMonth() + 1).padStart(2, '0')}.${String(releaseDate.getDate()).padStart(2, '0')}`
                  return (
                    <div className="newsTopics" key={news.id}>
                      <div className="newsDetail flex">
                        <Link to={`/news/${news.id}`} className="newsDetailChild flex">
                          <div className="date">
                            <div className="flex align-start w-full">
                              <span>{year}</span>
                            </div>
                            <div className="flex align-start w-full">
                              <span>{monthDay}</span>
                            </div>
                          </div>
                          <div className="article flex column">
                            <div>
                              <span>{news.title}</span>
                            </div>
                            <div>
                              <span>{news.enTitle}</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
              {/* <div className="allView">
              <div className="flex justify-end text-decoration-underline">
                <Link to="/news">{capitalize("all view")}</Link>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="spTopNews w-full h-full" ref={blur.ref}>
        <div className="container flex column w-full h-full relative">
          {blur.inView && (
            <div className="spNumber absolute user-select-none">
              <span>01</span>
            </div>
          )}
          <div className="content flex column align-start justify-center w-full h-full gap-50">
            <div className="flex column">
              <div
                className={`spTitle ${blur.inView ? "blurTitle" : "invisible"}`}
              >
                <span className="user-select-none">{capitalize("news")}</span>
              </div>
            </div>
            {blur.inView && (
              <div className="flex column gap-20 blurText">
                <div className="jaText">
                  <span>
                    わたしたちはブロックチェーン・NFTのテクノロジーを用いて『web3×エンタメ領域』の可能性を模索しつづけています。わたしたちが創り出す「モノ、コト」が日本のクリエイティブ文化の継続と発展、ひいては世界中のオタクカルチャーの持続可能性の一助となれることを切に願います。
                  </span>
                </div>
                <div className="enText">
                  <span>
                    We continue to explore the possibilities of "web3 x
                    entertainment" utilizing blockchain and NFT technologies. We
                    sincerely hope that the "things" we create will contribute
                    to the continuation and development of Japan's creative
                    culture, and by extension, the sustainability of Otaku
                    culture around the world.
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* ニュース紹介エリア */}

          {newsList.map((news) => {
            const releaseDate = new Date(news.releaseDate)
            const year = releaseDate.getFullYear()
            const monthDay = `${String(releaseDate.getMonth() + 1).padStart(2, '0')}.${String(releaseDate.getDate()).padStart(2, '0')}`
            const dayOfWeek = releaseDate.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
            return (
              <div className="content flex column align-start justify-center w-full h-full gap-50">
                <div className="flex column align-start justify-center gap-20">
                  <div className="year w-full">
                    <span>{year}</span>
                  </div>
                  <div className="date-image flex align-start justify-between w-full">
                    <div className="date flex">
                      <span className="first">{monthDay}</span>
                      <span className="second">({dayOfWeek})</span>
                    </div>
                    <div className="image">
                      <img loading="lazy" src={news.mainImage.url} alt={news.title} className="w-full" />
                    </div>
                  </div>
                  <div className="text flex column gap-20">
                    <div className="ja">
                      <span>{news.title}</span>
                    </div>
                    <div className="en">
                      <span>{news.enTitle}</span>
                    </div>
                  </div>
                </div>
                <div className="allview flex align-center justify-end w-full">
                  <span>
                    <Link to={`/news/${news.id}`}>All View</Link>
                  </span>
                </div>
              </div>
            )
          })}
          <div className="sp_marquee_container flex align-center jusfity-center">
            <div className="sp_marquee_content spnvd relative">
              <Marquee speed={20} className="marquee_text">
                <div className="user-select-none">
                  <Link to="/news">{capitalize("view all news ")}</Link>
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default TopNews;
