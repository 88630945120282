import { Link } from "react-router-dom";
import { getNews } from '../helper/microcmsClient';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import battleLogo from "../compressed/battleLogo.webp";

const capitalize = (text) => text.toUpperCase();

const Header = ({ isMobile, handlerToggle, mainImage }) => {
  if (isMobile) {
    return (
      <header className="fixed flex align-center justify-between w-full">
        <div className="logo flex align-center justify-center">
          <a href="/">
            <img loading="lazy" src={battleLogo} alt="" className="w-80" />
          </a>
        </div>
        <div className="menuBtn">
          <button onClick={handlerToggle}>{capitalize("menu")}</button>
        </div>
      </header>
    )
  } else {
    return (
      <aside className="sidebar flex gap10">
        <div className="header widget_sticky">
          <header className="flex column align-center justify-between">
            <div className="headerImage flex align-center justify-center">
              <Link to="/">
                <img loading="lazy" src={battleLogo} alt="" />
              </Link>
            </div>
            <ul className="flex align-center justify-center vertical_rl">
              <li>
                <Link to="/">{capitalize("home")}</Link>
              </li>
              <li>
                <Link to="/news">{capitalize("news")}</Link>
              </li>
              <li>
                <Link to="/projects">{capitalize("projects")}</Link>
              </li>
              <li>
                <Link to="/company">{capitalize("company")}</Link>
              </li>
            </ul>
          </header>
        </div>
        <div className="widget widget_sticky">
          {mainImage && <img loading="lazy" src={mainImage.url} alt="" className="w-full" />}
        </div>
      </aside>
    )
  }
};

const TitleSection = ({ title, enTitle }) => (
  <div className="title flex column gap-20">
    <div className="bigTitle ja">
      <span>
        {title}
      </span>
    </div>
    <div className="en">
      <span>
        {enTitle}
      </span>
    </div>
  </div>
);

const ArticleSection = ({ content }) => (
  <div className="topic flex column gap-50">
    <div className="ja flex column gap-20" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);

const AllNewsLink = () => (
  <div className="toNewsPage flex align-center justify-center w-full">
    <Link to="/news">
      <span>{capitalize('all news')}</span>
    </Link>
  </div>
);

const Topic = ({ useWindowWidth, handlerToggle }) => {
  const { id } = useParams();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 496;
  const [news, setNews] = useState(null);
  useEffect(() => {
    getNews(id)
      .then((res) => setNews(res))
      .catch((error) => {
        // エラー発生時の処理
        alert('ページが見つかりません');
        window.location.href = '/'; // トップページにリダイレクト
      });
  }, [id]);

  return (
    <section id={isMobile ? "spEachNews" : "EachNews"} className={isMobile ? 'spEachNews' : ''}>
      <div className={isMobile ? 'spDetailContainer' : 'eachWrapper w-full h-full'}>
        <Header isMobile={isMobile} handlerToggle={handlerToggle} mainImage={news ? news.mainImage : ''} />
        {isMobile && (
          <div className="contactText fixed">
            <span>{capitalize('contact')}</span>
          </div>
        )}
        {news ?
          <main className={`main flex column align-start justify-start ${isMobile ? 'spEachNewsContainer flex column gap-50' : 'gap-100'}`}>
            <TitleSection title={news.title} enTitle={news.enTitle} />
            {isMobile &&
              <div>
                <img loading="lazy" src={news.mainImage.url} alt="" className="w-full" />
              </div>
            }
            <ArticleSection content={news.content} />
            <AllNewsLink />
          </main> : <div>Loading...</div>
        }
      </div>
    </section>
  );
};

export default Topic;
